<template>
	<div class="Share bgfff br4 p32">
		<div class="nav fs16 fw-7 font-33 pb24 pr00 flex">
			<div class="tit-line flex">
				<span class="pr">分享APP</span>
				<span class="line">分享APP</span>
			</div>
		</div>

		<div class="con mt24 flex flex-direction align-center">
			<div class="invite-code bgfff flex align-center">
				{{ userInfo.invite_code
				}}<span
					v-clipboard:copy="userInfo.invite_code"
					v-clipboard:success="copySuccess"
					v-clipboard:error="copyError"
					>复制</span
				>
			</div>
			<div class="mt24 fs14 font-33">
				邀请好友时，可填此邀请码
				<i @click="guiHandel" class="iconfont cursor">&#xe600;</i>
			</div>
			<img
				class="qrcode-url"
				:src="qrcode_url || require('@/assets/logo.png')"
			/>
			<div
				v-clipboard:copy="url"
				v-clipboard:success="copySuccess"
				v-clipboard:error="copyError"
				class="btn"
			>
				分享二维码
			</div>
			<div class="mt24 fs14 font-33">点击分享链接按钮，自动复制下载APP链接</div>
		</div>
		<!-- 邀请规则弹窗 -->
		<el-dialog
			class="integral-popup-wrap"
			center
			:visible.sync="integralVisible"
			width="220px"
			style="border-radius: 24px"
		>
			<div
				class="popup bgfff br24 pr00 w100 p32"
				style="min-height: 100px; text-align: center"
				v-html="content"
			></div>
		</el-dialog>
	</div>
</template>
<script>
import { mapState } from "vuex";
export default {
	name: "Share",
	data() {
		return {
			head_img: "",
			qrcode_url: "",
			url: "",
			user_nickname: "",
			content: "",
			integralVisible: false,
		};
	},
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
	},
	created() {
		this.getData();
		this.$pageScrollTop();
		//	if (this.$$store.state.userInfo.user_token > 4) {
		this.$API.shareAjax().then((res) => {
			if (res.code == 1) {
				Object.keys(res.data).forEach((item) => {
					this[item] = res.data[item];
				});
			} else {
				this.$message.info(res.msg);
			}
		});

		//	}
	},
	methods: {
		guiHandel() {
			this.integralVisible = true;
		},
		getData() {
			this.$API.getAgreement({ category_id: "132" }).then((res) => {
				this.loading = false;
				if (res.code == 1) {
					this.content = res.data.content;
					console.log(this.content);
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		copySuccess() {
			this.$message.success("复制成功");
		},
		copyError() {
			this.$message.error("复制失败");
		},
	},
};
</script>
<style scoped lang="scss">
.Share {
	@extend .w100;
	@extend .h100;

	.nav {
		border-bottom: 1px dashed #eeeeee;
	}

	.con {
		background: url(../../assets/imgs/about-share-bg.png) no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 628px;

		.invite-code {
			padding: 16px 60px 12px 80px;
			font-size: 22px;
			font-weight: bold;
			color: #ff5800;
			margin-top: 56px;

			span {
				cursor: pointer;
				margin-left: 24px;
				font-size: 16px;
				color: #4177dd;
			}
		}

		.qrcode-url {
			margin-top: 70px;
			width: 200px;
			height: 200px;
		}

		.btn {
			margin-top: 70px;
			cursor: pointer;
			text-align: center;
			width: 240px;
			height: 48px;
			line-height: 48px;
			background: #ff8300;
			border-radius: 24px;
			font-size: 16px;
			color: #ffffff;
		}
	}
}
</style>
